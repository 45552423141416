<template>
  <div>
    <div class="grid grid-cols-3 gap-4">
      <div class="lg:col-span-2 col-span-3 clearfix">
        <div class="float-left flex gap-1 items-center whitespace-nowrap lg:mt-4 clearfix">
          <div class="cursor-pointer">
            <div v-if="stockRequest.status === 'submitted'" class="flex px-4 py-2 status-btn" @click="addTracking('ready')">
              <span class="text-sm">{{ $t('Ready') }}</span>
            </div>
          </div>
          <div class="cursor-pointer">
            <div v-if="stockRequest.status === 'ready'" class="flex px-4 py-2 status-btn" @click="loadStockRequest('received')">
              <span class="text-sm">{{ $t('Receive WRO') }}</span>
            </div>
          </div>
          <div class="cursor-pointer">
            <div v-if="allSuccessAndFailed && stockRequest.status === 'received'" class="flex px-4 py-2 status-btn" @click="loadStockRequest('inbounded')">
              <span class="text-sm">{{ $t('Inbound WRO') }}</span>
            </div>
          </div>
          <div class="cursor-pointer">
            <div v-if="stockRequest.status === 'inbounded'" class="flex px-4 py-2 status-btn" @click="loadStockRequest('stored')">
              <span class="text-sm">{{ $t('Store WRO') }}</span>
            </div>
          </div>
          <div class="px-4 py-2 mr-2 flex items-center cursor-pointer available-action" @click="downloadPDF()">
            <feather-icon icon="PrinterIcon" svgClasses="w-4 h-4"/>
            <span class="text-sm ml-2">{{ $t('Print WRO') }}</span>
          </div>
          <download-button class="mr-4 float-left whitespace-nowrap" :totalRows="totalRows"></download-button>
          <create-uro-button :UROstock="stockRequest" :selected="selected" @selected="selected = $event" />
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          @search="handleSearch"
          @keyup.enter.native="handleSearch"
          search
          :data="stockProducts"
          :tableLoader="tableLoader"
        >
          <template slot="thead">
            <shipblu-th>{{$t('Product Image')}}</shipblu-th>
            <shipblu-th>{{$t('ShipBlu Inventory ID')}}</shipblu-th>
            <shipblu-th>{{$t('SKU')}}</shipblu-th>
            <shipblu-th>{{$t('Product Name')}}</shipblu-th>
            <shipblu-th>{{$t('Size')}}</shipblu-th>
            <shipblu-th>{{$t('Color')}}</shipblu-th>
            <shipblu-th>{{$t('Dimensions')}}</shipblu-th>
            <shipblu-th>{{$t('Weight')}}</shipblu-th>
            <shipblu-th>{{$t('Incoming Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('Received Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('Inbounded Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('QC Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('Flags')}}</shipblu-th>
            <shipblu-th>{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].image">
                <img :src="data[indextr].product ? data[indextr].product.image : ''" class="w-10 h-10"/>
              </shipblu-td>

              <shipblu-td :data="data[indextr].requestID">
                {{ data[indextr].product.id }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].sku">
                {{ data[indextr].product.sku }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].productName">
                {{ data[indextr].product.name }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].size">
                {{ data[indextr].product.size }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].color">
                <div class="color-container h-6 w-3/4">
                  <div class="w-full h-full" :style="`background-color:${data[indextr].product.color}`"></div>
                </div>
              </shipblu-td>

              <shipblu-td :data="data[indextr].dimensions">
                {{ data[indextr].product.d_length }} x {{ data[indextr].product.d_width }} x {{ data[indextr].product.d_height }}
              </shipblu-td>
            
              <shipblu-td :data="data[indextr].size">
                {{ data[indextr].product.weight }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].incomingQuantity">
                {{ data[indextr].incoming_quantity }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].receivedQuantity">
                {{ data[indextr].received_quantity }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].inboundedQuantity">
                {{ data[indextr].inbounded_quantity }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].qcStatus">
                <div class="flex items-center justify-center">
                  <feather-icon icon="CheckCircleIcon" v-if="data[indextr].qc_status === 'success'" class="w-5 h-5 text-green"/>
                  <feather-icon icon="CheckCircleIcon" v-if="data[indextr].qc_status === 'initial'" class="w-5 h-5 text-grey"/>
                  <feather-icon icon="XCircleIcon" v-if="data[indextr].qc_status === 'failed'" class="w-5 h-5 text-red" />
                </div>
              </shipblu-td>

              <shipblu-td :data="data[indextr].flags">
                <p v-for="(item, index) in data[indextr].flags" :key="index">
                  {{ item.name }}
                </p>
              </shipblu-td>

              <shipblu-td>
                <div @click="removeAttribute($event)">
                  <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer"
                    @click="selected = []"
                  >
                    <vs-button
                      type="border"
                      size="small"
                      icon-pack="feather"
                      icon="icon-more-horizontal"
                    >
                    </vs-button>
                    <vs-dropdown-menu style="width: 190px">
                      <vs-dropdown-item
                        @click="openStockProductModal('Edit Product', data[indextr])"
                        :class="stockRequest.status !== 'ready' ? 'disabled' : 'text-blue-100'"
                      >
                        <span class="flex gap-2 items-center">
                          <feather-icon
                            icon="EditIcon"
                            svgClasses="h-6 w-6"
                          />
                          <span>{{$t('Receive QTY')}}</span>
                        </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item 
                        :class="(stockRequest.status === 'partially_received' || stockRequest.status === 'received') && data[indextr].qc_status === 'initial'  ? 'text-blue-100' : 'disabled'"
                        @click="openQCProductModal(data[indextr])"
                      >
                        <span class="flex gap-2 items-center">
                          <feather-icon
                            icon="EditIcon"
                            svgClasses="h-6 w-6"
                          />
                          <span>{{$t('QC Product')}}</span>
                        </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        :class="stockRequest.status === 'received' && (data[indextr].qc_status === 'success' ) && ($store.state.AppActiveUser.userRole === 'fc-inbounding-agent' || $store.state.AppActiveUser.userRole === 'fc-operations-supervisor') ? 'text-blue-100' : 'disabled'"
                        @click="openInboundProductModal(data[indextr])"
                      >
                        <span class="flex gap-2 items-center">
                          <feather-icon
                            icon="EditIcon"
                            svgClasses="h-6 w-6"
                          />
                          <span>{{$t('Inbound QTY')}}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>

        <shipblu-prompt
          class="shipment-modal"
          @close="closeModal"
          :active.sync="qcProduct"
          :title="$t('Product QC')"
          :buttons-hidden="true">
          <div class="sm:flex items-center gap-4 mb-base mt-6">
            <div class="sm:w-2/3 w-full">
              <img class="w-full h-full" :src="qcProductData.product.image" alt="product image"/>
            </div>
            <div class="font-medium text-base whitespace-nowrap sm:mt-0 mt-6">
              <p class="font-semibold text-xl mb-5">{{$t('Hard Check Product Details')}}</p>
              <p class="text-lg">{{$t(`SKU: ${qcProductData.product.sku}`)}}</p>
              <p class="text-lg">{{$t(`Product Name: ${qcProductData.product.name}`)}}</p>
              <p class="text-lg">{{$t(`Size: ${qcProductData.product.size ? qcProductData.product.size : ''}`)}}</p>
              <p class="text-lg">{{$t(`Color: ${qcProductData.product.color}`)}}</p>
              <p class="text-lg">{{$t(`Dimensions: ${ qcProductData.product.d_length } x ${ qcProductData.product.d_width } x ${ qcProductData.product.d_height }`)}}</p>
            </div>
          </div>
          <div class="w-full flex justify-between gap-2">
            <button @click="openUROModal(qcProductData)" class="btn disable-btn w-full">{{$t('Create URO')}}</button>
            <button @click="confirmQC(qcProductData, 'success')" class="active-btn btn w-full">{{$t('Confirm QC')}}</button>
          </div>
        </shipblu-prompt>
        <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
      </div>

      <div class="lg:col-span-1 col-span-3">
        <shipblu-card :cardLoading="stockRequestLoading" class="lg:mt-5 p-5">
          <p class="text-blue-900 text-xl font-medium leading-tight mb-4">{{$t('Stock Request Info')}}</p>
          <div>
            <span class="text-darkgray text-lg mr-1">{{$t('Fulfillment Center')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRequest.fulfillment_center.name}}</span>
          </div>
          <div class="mt-1 flex items-center">
            <span class="text-darkgray text-lg mr-1">{{$t('Number Of Vehicles')}}:</span>
            <span v-if="!editable" class="text-primary text-lg font-medium">{{stockRequest.num_vehicles}}</span>
            <div v-else class="ml-2">
              <vs-input-number min="1" v-validate="'required|numeric'" name="no. of vehicles" v-model.number="stockRequest.num_vehicles" class="stock-request-input-number flex justify-start"/>
              <p class="text-danger text-sm" v-show="errors.has('no. of vehicles')">{{ errors.first('no. of vehicles') }}</p>
            </div>
            <feather-icon @click="editable = true" v-if="!editable && stockRequest.status === 'submitted'" class="help-icon cursor-pointer" icon="EditIcon" svgClasses="w-6 h-6 ml-10 text-primary"></feather-icon>
            <template v-if="editable">
              <vs-button color="success"  @click="updateURO" icon-pack="feather" icon="icon-check-circle" class="float-right rounded-full block m-3"></vs-button>
              <vs-button color="danger" @click="cancelEdit" icon-pack="feather" icon="icon-x-circle" class="float-right rounded-full block"></vs-button>
            </template>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Merchant')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRequest.merchant ? stockRequest.merchant.name : 'N/A'}}</span>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Merchant ID')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRequest.merchant ? stockRequest.merchant.id : 'N/A'}}</span>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Total SKUs')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRequest.number_of_skus}}</span>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Total Quantity')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRequest.total_quantity}}</span>
          </div>
        </shipblu-card>
        <shipblu-card class="lg:mt-5 p-5" :cardLoading="UROsLoading">
          <p class="text-blue-900 text-xl font-medium leading-tight mb-4">{{$t('Linked UROs')}}</p>
          <div v-for="uro in UROs" :key="uro.index">
            <span class="text-darkgray text-lg">{{$t('ID')}}:</span>
            <span class="text-primary text-lg font-medium cursor-pointer" @click="openURO(uro)"> {{uro.id}}</span>
          </div>
        </shipblu-card>
        <stock-request-tracking :stockRequest="stockRequest" :trackings="trackings" @loadTrackings="loadTrackings" :trackingsLoading="trackingsLoading"/>
      </div>
    </div>
    <edit-product-in-stock :stockRequest="stockRequest" :stockProduct="stockProduct" :stockProductModal="stockProductModal"
    @loadStockProducts="loadStockProducts" @stockProductModal="stockProductModal = $event" />
    <inbound-product :inboundProductModal="inboundProductModal" @inboundProductModal="inboundProductModal = $event" :stockProduct="stockProduct" @loadStockProducts="loadStockProducts" />
    <create-uro-modal :addUROModal="addUROModal" @addUROModal="addUROModal = $event" :qcProductData="qcProductData" @confirmQC="confirmQC" />
  </div>
</template>

<script>
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import EditProductInStock from './components/EditProductInStock.vue'
import axios from 'axios'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import i18nData from '../../i18n/i18nData.js'
import InboundProduct from './components/InboundProduct.vue'
import CreateUroButton from './components/CreateUROButton.vue'
import CreateUroModal from './components/CreateUROModal.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import StockRequestTracking from './components/StockRequestTracking.vue'
import DownloadButton from './components/DownloadStock.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      editable: false,
      stockProductModal: false,
      trackings: [],
      trackingsLoading: false,
      stockProducts: [],
      stockRequest: {
        fulfillment_center: {},
        merchant: {}
      },
      stockRequestLoading: false,
      stockProduct: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      UROs: [],
      UROsLoading: false,
      stockStatus: '',
      qcProduct: false,
      qcProductData: {
        product: {}
      },
      inboundProductModal : false,
      UROProducts: [],
      tableLoader: false,
      addUROModal: false,
      allSuccessAndFailed: false,
      totalProductsReceived: 0,
      totalProductsInbounded: 0,
      totalProductsStored: 0,
      totalProductsIncoming: 0
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    EditProductInStock,
    ShipbluPrompt,
    InboundProduct,
    CreateUroButton,
    CreateUroModal,
    ShipbluCard,
    ShipbluPagination,
    StockRequestTracking,
    DownloadButton
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStockProducts()
    }
  },
  methods: {
    conditionalStock () {
      const numberOfSuccessAndFails = []
      const numberOfSuccess = []
      this.stockProducts.forEach(item => { 
        if (item.qc_status === 'success' || item.qc_status === 'failed') {
          numberOfSuccessAndFails.push(item)
          if (item.qc_status === 'success') {
            numberOfSuccess.push(item)
          }
        }
      })
      if (this.stockRequest.status === 'received' && this.stockProducts.length === numberOfSuccessAndFails.length && numberOfSuccess.length > 0) {
        this.allSuccessAndFailed = true
      }
    },
    addTracking (status) {
      this.stockStatus = status
      switch (status) {
      case 'received':
        this.$vs.dialog({
          color: 'success',
          title: 'Confirm',
          text: `${i18nData[this.$i18n.locale]['Are you sure you want to confirm receiving']} ${this.stockRequest.total_received_quantity} ${i18nData[this.$i18n.locale]['Product(s) out of']} ${this.stockRequest.total_quantity} ${i18nData[this.$i18n.locale]['Product(s)?']}`,
          accept: this.confirmAddTracking
        })
        break
      case 'inbounded':
        this.$vs.dialog({
          color: 'success',
          title: 'Confirm',
          text: `${i18nData[this.$i18n.locale]['Are you sure you want to confirm inbounding']} ${this.stockRequest.total_inbounded_quantity} ${i18nData[this.$i18n.locale]['Product(s) out of']} ${this.stockRequest.total_received_quantity} ${i18nData[this.$i18n.locale]['Product(s)?']}`,
          accept: this.confirmAddTracking
        })
        break
      case 'stored':
        this.$vs.dialog({
          color: 'success',
          title: 'Confirm',
          text: `${i18nData[this.$i18n.locale]['Are you sure you want to confirm storing']} ${this.stockRequest.total_inbounded_quantity} ${i18nData[this.$i18n.locale]['Product(s) out of']} ${this.stockRequest.total_inbounded_quantity} ${i18nData[this.$i18n.locale]['Product(s)?']}`,
          accept: this.confirmAddTracking
        })
        break
      case 'ready':
        this.confirmAddTracking()
        break
      }
    },
    confirmAddTracking () {
      const tracking = {
        stock_request: this.$route.params.stockID,
        status: this.stockStatus
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/tracking/`, 'post', tracking, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.stockStatus = ''
          this.loadTrackings()
          if (this.stockStatus === 'ready') {
            let submittedStocks = localStorage.getItem('createdStockRequests')
            submittedStocks = Number(submittedStocks) - 1
            localStorage.setItem('createdStockRequests', submittedStocks)
          }
        }
      )
    },
    downloadPDF () {
      axios({
        url: `${process.env.VUE_APP_FULFILLMENT_URL}/api/v1/fc/stock-requests/${this.$route.params.stockID}/pdf/`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'WRO.pdf')
        document.body.appendChild(link)
        link.click()
      })
    },
    openURO (URO) {
      this.$router.push({
        name: 'fc-operations-supervisor-unidentified-stock-request-view',
        params: {
          stockID: URO.id
        }
      }).catch(() => {})
    },
    closeModal () {
      this.qcProduct = false
      this.loadStockRequest()
      this.loadStockProducts()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    openStockProductModal (type, data) {
      this.type = type
      this.stockProduct = data
      this.stockProductModal = true
    },
    cancelEdit () {
      this.editable = !this.editable
      this.loadStockRequest()
    },
    updateURO () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/`, 'patch', {num_vehicles: Number(this.stockRequest.num_vehicles)}, true,
        (response) => {
          this.editable = false
          this.stockRequest.num_vehicles = response.data.num_vehicles
        }
      )
    },
    loadStockRequest (type) {
      this.stockRequestLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/`, 'get', null, true,
        (response) => {
          this.stockRequest = response.data
          this.stockRequestLoading = false
          this.loadUROs()
          if (type) {
            this.addTracking(type)
          }
        }
      )
    },
    loadStockProducts () {
      this.totalProductsReceived = 0
      this.totalProductsInbounded = 0
      this.totalProductsStored = 0
      this.totalProductsIncoming = 0
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/products/${query}`, 'get', null, true,
        (response) => {
          this.stockProducts = response.data.results
          this.tableLoader = false
          this.stockProducts.forEach(item => {
            this.totalProductsIncoming += item.incoming_quantity
            this.totalProductsReceived += item.received_quantity
            this.totalProductsInbounded += item.inbounded_quantity
            this.totalProductsStored += item.stored_quantity
          })
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.conditionalStock()
        }
      )
      this.searchInProgress = false
    },
    loadUROs () {
      this.UROsLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/unidentified-stock-requests/?stock_request=${this.$route.params.stockID}`, 'get', null, true,
        (response) => {
          this.UROs = response.data.results
          this.UROs.forEach(uro => {
            uro.unidentified_products.forEach(item => {
              this.UROProducts.push({
                product: item.product,
                status: uro.status
              })
            })
          })
          this.UROsLoading = false
        }
      )
    },
    loadTrackings () {
      this.trackingsLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/tracking/`, 'get', null, true,
        (response) => {
          this.trackings = response.data.results
          this.stockRequest.status = this.trackings[0].status
          this.trackingsLoading = false
        }
      )
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductSearch)
    },
    loadProductSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStockProducts()
    },
    confirmQC (qcData, status) {
      const data = {
        qc_status: status
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/products/${qcData.id}/`, 'patch', data, true,
        () => {
          this.closeModal()
        }
      )
    },
    openUROModal (data) {
      this.addUROModal = true
      this.qcProduct = false
      this.qcProductData = data
      this.qcProductData.reason = {
        name: 'Product description is not matched',
        value: 'product_not_matched'
      }
    },
    openQCProductModal (data) {
      this.qcProduct = true
      this.qcProductData = data
    },
    openInboundProductModal (data) {
      this.inboundProductModal = true
      this.stockProduct = data 
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStockRequest()
    this.loadStockProducts()
    this.loadTrackings()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.shipment-modal {
  .vs-dialog {
    max-width: 1000px !important;
    max-height: 90%;
  }
}
.con-pagination-table{
  display: none;
}
.disabled {
  color: #C5CBD0;
  pointer-events: none;
}
.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
  li {
    position: relative;
    .timeline-icon {
      position: absolute;
      top: 0;
      left: -3.2rem !important;
      border-radius: 50%;
      padding: 0px;
      padding: 0.3rem !important;
      padding-bottom: 0.4rem;
    }
  }
}
.product-order-status, .activity-e-status {
  margin-left: -30px;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  margin: auto !important;
  div {
    border-radius: 5px;
  }
}
</style>